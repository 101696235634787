//=include lib/jquery.min.js

(function($, window, document) {


  // Global navigation SP
  (function () {

    var siteNav = {

      el: {
        $toggle: $('.js_nav-toggle'),
        $wrapperOuter: $('.page'),
        $wrapper: $('.o-wrapper'),
        $overlay: $('.js_nav-overlay'),
        $navLinks: $('.nav-sp-list-item a')
      },

      config: {
      },

      init: function () {
        this.menuClickHandler();
        this.linkClickHandler();
      },

      menuClickHandler: function () {
        var _this = this;

        this.el.$toggle.on('click', function (e) {
          e.preventDefault();

          _this.toggleNav();

          return 0;
        });
      },

      linkClickHandler: function () {
        var _this = this;

        this.el.$navLinks.on('click', function (e) {
          _this.toggleNav();
        });
      },

      toggleNav: function () {
        var _this = this;
        var animationTime = 500;

        _this.toggleSliding();
        _this.toggleOverlay();

        // If the wrapper div has already been activated delay setting the class with the amount of time equal to the CSS animation
        // if (_this.el.$wrapperOuter.hasClass('nav-active')) {
        //   setTimeout(function() {
        //     _this.toggleFixedScreen();
        //   }, animationTime);
        // } else {
        //   _this.toggleFixedScreen();
        // }

      },

      toggleOverlay: function () {
        this.el.$overlay.toggleClass('active');
      },

      toggleSliding: function () {
        this.el.$wrapper.toggleClass('nav-active');
      },

      toggleFixedScreen: function () {
        this.el.$wrapperOuter.toggleClass('nav-active');
      }

    };

    siteNav.init();

  })();


  // Case study accordion
  (function() {

    $('.case-study-link').on('click', function (e) {
      e.preventDefault();

      var $link = $(this);

      $link.parent().find('.js_case-study').removeClass('active');
      $link.next().next().addClass('active');

      $link.parent().find('.js_case-study-link').removeClass('active');
      $link.parent().find('.js_case-study-link-text').removeClass('active');
      $link.next().addClass('active');
      $link.addClass('active');

      return 0;
    });

  })();


  // Slide to section links
  (function() {

    $( '.js_link' ).on( 'click', function(e) {
      e.preventDefault();
      var target = $(this).attr('href');
      var offset = $( target ).offset().top;
      $('html,body').animate({ scrollTop: offset }, 700);
      return 0;
    });

  })();


  // flipper sp
  (function() {

    $('.address').on('click', function (e) {
      var windowWidth = $(window).width();

      if (windowWidth < 768) {
        e.preventDefault();

        $(this).toggleClass('active');

        return 0;
      }
    });

  })();


  // Initiate contact nav
  (function () {

    setTimeout(function () {
      $('.js_nav-contact').addClass('active');
    }, 500);

  })();


  // Slider
  (function () {

    $('.js_slider').on('init', function () {
      
      $('.c-slider__item').equalHeights();

      $(window).on('resize', function(e) {  

        var _this = this;
        var resizeTimer;      

        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {

          $('.c-slider__item').css('height', 'inherit').equalHeights();

        }, 10);

      });      

    });

    // $(window).on('resize', function(e) {  

    //   var _this = this;
    //   var resizeTimer;      

    //   clearTimeout(resizeTimer);
    //   resizeTimer = setTimeout(function() {

    //     console.log('test');

    //     $('.js_slider').on('init', function () {
          
    //       $('.c-slider__item').equalHeights();

    //     });

    //   }, 10);

    // });      

    // match heights - tradeshow cards
    $('.js_slider').on('init', function () {
      var adjustHeight = function () {
        $('.card-text__header').matchHeight();
        $('.card-text__header-description').matchHeight();
        $('.card-text__content').matchHeight();
        $('.c-slider__item').matchHeight();  
        $('.card-text').matchHeight();        
      };

      adjustHeight();  
    });

    $('.js_slider').slick({
      dots: true,
      infinite: false,
      speed: 700,
      slidesToShow: 3,
      slidesToScroll: 3,
      prevArrow: $('.js_slider-arrow-left'),
      nextArrow: $('.js_slider-arrow-right'),
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            vertical: false,
            speed: 700
          }
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });  

  })();


})(window.jQuery, window, document);
